<script>
import AntInput from '@/components/AntInput.vue';
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import { dhmeTasksTemplate } from '@/modules/daiwa-house-modular-europe/ProjectIntake/DhmeTasksTemplate';
import { importTasksV2, queryTasksV2 } from '@/services/api/v2/tasks.v2.api';
import TasksGanttChart from '@/components/Charts/TasksGanttChart.vue';
import TasksGanttDefaultItem from '@/components/Charts/TasksGanttDefaultItem.vue';
import { updateRecordV2 } from '@/services/api/v2/records.v2.api';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { nonDhmeTasksTemplate } from '@/modules/daiwa-house-modular-europe/ProjectIntake/NonDhmeTasksTemplate';
import tasks from '@/views/Tasks.vue';

export default {
  name: 'DhmeProjectIntakePlanning',
  components: { TasksGanttDefaultItem, TasksGanttChart, AntInput },
  data: () => {
    return {
      startDate: null,
      startDateMenu: false,
      endDate: null,
      endDateMenu: false,
      tasks: [],
      plannerLoading: false,
      planningData: null,
      planningDataTableId: null,
      prognosedMenu: false,
      contractedMenu: false,
      phaseStart: null,
      placementPhaseCount: 1,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      masterProject: 'project',
      intakeProject: 'dhmeProjectIntakeStore/selectedProject',
      metadata: 'dhmeProjectIntakeStore/selectedProjectMetadata',
    }),

    prognosedDate() {
      if (this.planningData?.prognosed_handover_date) {
        return moment(this.planningData.prognosed_handover_date).format(
          'YYYY-MM-DD'
        );
      }
      return null;
    },

    contractedDate() {
      if (this.planningData?.contracted_handover_date) {
        return moment(this.planningData.contracted_handover_date).format(
          'YYYY-MM-DD'
        );
      }
      return null;
    },

    formattedPrognosedDate() {
      return moment(this.planningData?.prognosed_handover_date).format(
        'DD-MM-YYYY'
      );
    },

    formattedContractedDate() {
      return moment(this.planningData?.contracted_handover_date).format(
        'DD-MM-YYYY'
      );
    },

    formattedStartDate() {
      if (this.startDate) {
        return moment(this.startDate).format('DD-MM-YYYY');
      }
      return null;
    },

    formattedEndDate() {
      if (this.endDate) {
        return moment(this.endDate).format('DD-MM-YYYY');
      }
      return null;
    },

    duration() {
      if (this.endDate && this.startDate) {
        return moment(this.endDate).diff(moment(this.startDate), 'days');
      }
      return 0;
    },

    slack() {
      if (this.endDate && this.planningData?.contracted_handover_date) {
        return moment(this.planningData?.contracted_handover_date).diff(
          moment(this.endDate),
          'days'
        );
      }
      return 0;
    },
  },
  async mounted() {
    // fetch tasks
    const { tasks } = await queryTasksV2(this.project.license, [
      {
        column: 'project',
        operator: '=',
        values: [this.intakeProject.id],
      },
      {
        column: 'type',
        operator: '=',
        values: [
          'dhme-init-phase',
          'dhme-structure-design-phase',
          'dhme-temporary-design-phase',
          'dhme-definitive-design-phase',
          'dhme-environmental-permit-phase',
          'dhme-production-phase',
          'dhme-assembly-phase',
          'dhme-factory-phase',
          'dhme-placement-phase',
          'dhme-construction-site-phase',
          'dhme-contact-formation-phase',
          'dhme-production-drawing-phase',
          'dhme-deliver-time-phase',
          'dhme-install-assemble-finish-phase',
        ],
      },
    ]);

    this.tasks = tasks;

    await this.fetchPlanningData();
  },
  methods: {
    async planTasks() {
      this.plannerLoading = true;
      let tasks = [];
      let date = moment(this.startDate);
      let template =
        this.metadata.firm === 'Daiwa House'
          ? [...dhmeTasksTemplate]
          : [...nonDhmeTasksTemplate];

      // Adjust the template based on phaseStart if needed
      if (this.phaseStart) {
        const phaseStartIndex = template.findIndex(
          (t) => t.options.type === this.phaseStart
        );
        template = template.slice(phaseStartIndex);
      }

      // Calculate total weight of the top-level tasks
      const totalWeight = template
        .filter((t) => !t.parent)
        .reduce((acc, t) => acc + t.weight, 0);

      const allPredecessors = template.map((x) => x.predecessors);

      for (const task of template) {
        // Skip child tasks; they'll be processed with their parent
        if (task.parent) continue;

        const endDate = date
          .clone()
          .add(this.duration * (task.weight / totalWeight), 'days')
          .format('YYYY-MM-DD');

        const updateTask = this.tasks.find(
          (t) => t.task_type.type === task?.options?.type
        );

        // Create the parent task
        const obj = this.setTaskData(
          allPredecessors,
          task,
          date,
          endDate,
          updateTask
        );

        tasks.push(obj);

        if (task.hasChildren) {
          if (task.options.type === 'dhme-construction-site-phase') {
            // Process placement phases dynamically based on placementPhaseCount
            const placementPhaseCount = this.placementPhaseCount || 1; // default to 1 if undefined
            let childDate = date.clone(); // Start from the parent's start date

            // Calculate parent's duration in days
            const parentDuration = moment(endDate).diff(moment(date), 'days');

            // Each child will have duration proportional to 1 / placementPhaseCount
            const childDuration = parentDuration / placementPhaseCount;

            // Get existing placement tasks sorted by a consistent property
            const existingPlacementTasks = this.tasks
              .filter((t) => t.task_type.type === 'dhme-placement-phase')
              .sort((a, b) => a.number - b.number); // Assuming 'number' is a sequence

            for (let i = 1; i <= placementPhaseCount; i++) {
              const childStartDate = childDate.clone();
              const childEndDate = childStartDate
                .clone()
                .add(childDuration, 'days')
                .format('YYYY-MM-DD');

              // Try to find existing task to update
              let updateTask = existingPlacementTasks[i - 1]; // zero-based index

              const childObj = this.setTaskData(
                allPredecessors,
                {
                  friendly_id: `${task.friendly_id}0${i}`,
                  title: `Placement Phase ${i}`,
                  options: {
                    type: 'dhme-placement-phase',
                  },
                  parent: obj.friendly_id, // parent friendly_id
                },
                childStartDate,
                childEndDate,
                updateTask
              );

              tasks.push(childObj);

              // Update childDate for the next child
              childDate = moment(childEndDate);
            }

            // After processing children, update the date to the parent's end date
            date = moment(endDate);
          } else {
            // Process other child tasks from the template
            const children = template.filter(
              (t) => t.parent === task.friendly_id
            );
            const totalChildWeight = children.reduce(
              (sum, child) => sum + child.weight,
              0
            );
            let childDate = date.clone(); // Start from the parent's start date

            // Calculate parent's duration in days
            const parentDuration = moment(endDate).diff(moment(date), 'days');

            for (const child of children) {
              const childDuration =
                parentDuration * (child.weight / totalChildWeight);
              const childEndDate = childDate
                .clone()
                .add(childDuration, 'days')
                .format('YYYY-MM-DD');
              const childUpdateTask = this.tasks.find(
                (t) => t.task_type.type === child?.options?.type
              );

              const childObj = this.setTaskData(
                allPredecessors,
                child,
                childDate,
                childEndDate,
                childUpdateTask
              );

              tasks.push(childObj);

              // Update the childDate for the next child
              childDate = moment(childEndDate);
            }

            // After processing children, update the date to the parent's end date
            date = moment(endDate);
          }
        } else {
          // For tasks without children, update the date to the task's end date
          date = moment(obj.planned_end);
        }
      }

      try {
        // Import tasks and finalize planning
        this.tasks = await importTasksV2(tasks);
      } catch (error) {
        console.error('Error importing tasks:', error);
      }

      this.plannerLoading = false;
    },

    setTaskData(allPredecessors, task, date, endDate, updateTask) {
      let obj;

      // Determine if it's an existing task to update, or a new task to create
      if (updateTask) {
        obj = {
          id: updateTask.id,
          friendly_id: task.friendly_id,
          planned_start: date.clone().format('YYYY-MM-DD'),
          planned_end: endDate,
          parent: task.parent,
        };
      } else {
        obj = {
          friendly_id: task.friendly_id,
          title: task.title,
          planned_start: date.clone().format('YYYY-MM-DD'),
          planned_end: endDate,
          project: this.planningData.project_id,
          options: task.options,
          predecessors: task?.predecessors?.filter((p) =>
            allPredecessors.includes(p)
          ),
          parent: task.parent,
        };
      }

      return obj;
    },
    async fetchPlanningData() {
      const { planningdata } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_PROJECT_INTAKE_DATA',
            project: this.masterProject.id,
            as: 'planningdata',
            columns: [
              {
                name: 'project_id',
                conditions: [
                  {
                    operator: '=',
                    value: this.intakeProject.id,
                  },
                ],
              },
              ...['prognosed_handover_date', 'contracted_handover_date'].map(
                (key) => ({ name: key })
              ),
            ],
          },
        ],
      });

      this.planningData = planningdata.records[0];
      this.planningDataTableId = planningdata.id;
      this.isLoading = false;
    },

    async updatePlanningData(key, value) {
      this.isLoading = true;

      try {
        await updateRecordV2(this.planningDataTableId, this.planningData.id, {
          [key]: value,
        });
        this.planningData[key] = value;
      } catch (e) {
        this.$store.commit('showNotification', {
          content: e.message,
          color: 'error',
        });
      } finally {
        this.isLoading = false;
        this.prognosedMenu = false;
        this.contractedMenu = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-center">
      <ant-input
        label="Voorspelde opleverdatum (optioneel)"
        top-margin="mt-0"
        class="mr-2"
        is-optional
      >
        <template #input-field>
          <v-menu
            v-model="prognosedMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                :value="formattedPrognosedDate"
                filled
                dense
                prepend-inner-icon="mdi-calendar"
                hide-details
                placeholder="Datum"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="prognosedDate"
              show-week
              @input="
                (value) => updatePlanningData('prognosed_handover_date', value)
              "
            />
          </v-menu>
        </template>
      </ant-input>
      <ant-input
        label="Gecontracteerde opleverdatum"
        top-margin="mt-0"
        class="mr-2"
      >
        <template #input-field>
          <v-menu
            v-model="contractedMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formattedContractedDate"
                filled
                v-bind="attrs"
                dense
                prepend-inner-icon="mdi-calendar"
                hide-details
                placeholder="Datum"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :value="contractedDate"
              show-week
              @input="
                (value) => updatePlanningData('contracted_handover_date', value)
              "
            />
          </v-menu>
        </template>
      </ant-input>
      <ant-input label="Vanaf fase" top-margin="mt-0" class="mr-2">
        <template #input-field>
          <v-select
            v-model="phaseStart"
            filled
            item-text="text"
            item-value="value"
            dense
            hide-details
            placeholder="Phase"
            :items="[
              { text: 'IF', value: 'dhme-init-phase' },
              { text: 'SO', value: 'dhme-structure-design-phase' },
              { text: 'TO', value: 'dhme-temporary-design-phase' },
              { text: 'DO', value: 'dhme-definitive-design-phase' },
            ]"
          ></v-select>
        </template>
      </ant-input>
      <ant-input label="Aantal plaatsings fases" top-margin="mt-0">
        <template #input-field>
          <v-text-field
            v-model="placementPhaseCount"
            filled
            dense
            hide-details
            :min="1"
            :max="25"
            type="number"
            placeholder="Aantal"
          />
        </template>
      </ant-input>
    </div>
    <div class="d-flex mt-5">
      <ant-input label="Start datum vanaf fase" top-margin="mt-0" class="mr-2">
        <template #input-field>
          <v-menu
            v-model="startDateMenu"
            :disabled="!contractedDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formattedStartDate"
                prepend-inner-icon="mdi-calendar-start"
                readonly
                filled
                dense
                :disabled="!contractedDate || plannerLoading"
                hide-details
                placeholder="Datum"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              show-week
              @input="startDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </template>
      </ant-input>
      <ant-input
        label="Eind datum oplevering gereed"
        top-margin="mt-0"
        class="mr-2"
      >
        <template #input-field>
          <v-menu
            v-model="endDateMenu"
            :disabled="!contractedDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formattedEndDate"
                prepend-inner-icon="mdi-calendar-end"
                readonly
                filled
                :disabled="!contractedDate || plannerLoading"
                dense
                hide-details
                placeholder="Datum"
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              :disabled="!startDate"
              show-week
              :min="startDate"
              :max="contractedDate"
              @input="endDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </template>
      </ant-input>
      <ant-input label="Duur" top-margin="mt-0" class="mr-2" is-optional>
        <template #input-field>
          <v-text-field
            :value="duration"
            :disabled="!contractedDate || plannerLoading"
            prepend-inner-icon="mdi-calendar-clock"
            readonly
            filled
            dense
            hide-details
            placeholder="Day(s)"
          ></v-text-field>
        </template>
      </ant-input>
      <ant-input label="Speling" top-margin="mt-0" class="mr-2" is-optional>
        <template #input-field>
          <v-text-field
            :value="slack"
            :disabled="!contractedDate || plannerLoading"
            prepend-inner-icon="mdi-calendar-arrow-right"
            readonly
            filled
            dense
            hide-details
            suffix="Dag(en)"
          ></v-text-field>
        </template>
      </ant-input>
      <v-spacer />
      <v-btn
        color="primary"
        class="mt-5 flex-1"
        :loading="plannerLoading"
        :disabled="(!(duration > 0) && slack >= 0) || plannerLoading"
        @click="planTasks"
        >Uitplannen
      </v-btn>
    </div>
    <div
      class="ma-5 d-flex flex-column flex-1 flex-scroll-width overflow-x-auto"
      style="max-width: calc(100vw - 350px)"
    >
      <tasks-gantt-chart
        v-if="tasks.length > 0"
        :tasks="tasks.filter((t) => t.parent === null)"
      >
        <template #task-header-items>
          <tasks-gantt-default-item
            v-for="(task, index) in tasks.filter((t) => t.parent === null)"
            :key="`ant-gantt-header-${task.id}`"
            :task="task"
            :index="index"
            :parents="[]"
            :can-load-children="false"
          />
        </template>
      </tasks-gantt-chart>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
